import LeadHelpers from 'common/dist/virtuals/Lead';
import moment from 'moment';
import price from 'common/dist/price';
import { get, findIndex } from 'lodash';
import { RecosService } from 'spc/recos/recos.service';
import { ApiService } from 'spc/shared/api/api.service';

import { RawLead } from 'lib/database/types/lead';
import { getCityNameFromValue } from 'spc/utils/getCityDisplayName';
import { ArchiveService } from 'spc/shared/archive.service';

class ClientDashboardListController {
  showFullList: any = {};
  concludedEvents: boolean;
  selectedTab: string;
  leads: RawLead[];
  archivedLeads: RawLead[];
  showArchive: boolean;
  loading: boolean;
  splitLeads: boolean;
  memberConfirmedLeads: RawLead[] = [];
  memberConcludedLeads: RawLead[] = [];
  confirmedLeads: RawLead[] = this.memberConfirmedLeads || [];
  concludedLeads: RawLead[] = this.memberConcludedLeads || [];
  getCityNameFromValue = getCityNameFromValue;
  sortOrder: string;

  constructor(private $api: ApiService, private ENUMS, private $cloudinary, private $clickOk, private unwrapError, private $window, private $location, private archiveService: ArchiveService) {
    'ngInject';
  }

  $onInit = () => {
    if (this.splitLeads) {
      this.leads.forEach((lead) => {
        const yesterday = moment().subtract(1, 'day');
        if (lead['eventDate'] > yesterday) {
          this.confirmedLeads.push(lead);
        } else if (lead['eventDate'] < yesterday) {
          this.concludedLeads.push(lead);
        }
      });
    }
  }

  displayGuests = (numGuests) => {
    return numGuests.min && numGuests.max ?
      `${numGuests.min} - ${numGuests.max}` :
      numGuests.min ? `${numGuests.min} min` : `${numGuests.max} max`;
  }

  recommendationState = (recommendation) => {
    if (recommendation.conversation) {
      return recommendation.conversation.requestState;
    } else {
      return 'Recommended';
    }
  }
  getUnarchivedLead = (leads) => {
    return leads.filter(lead => !lead.archived);
  }

  getLondonBudget = (budget) => {
      return '£' + budget.toLocaleString();
    }

  archiveEvent = async (lead) => {
    return this.archiveService.archiveEvent(lead)
      .then((archiveResponse) => {
        if (get(archiveResponse, 'isArchived')) {
          const index = findIndex(this.leads, l => l._id === lead._id);
          this.leads.splice(index, 1);
          this.archivedLeads.push(get(archiveResponse, 'updatedLead'));
        }
      })
      .catch(error => this.unwrapError(error));
  }

  leadVenues = (lead) => {
    return lead.recommendations.length;
  }

  isRecoBooked = (reco) => {
    return this.ENUMS.bookingRequestState.paidStates.includes(get(reco, 'conversation.requestState'));
  }

  recosLength = (recoArray) => {
    return recoArray.filter(reco => !reco.archived).length;
  }

  openLeadPage = (lead) => {
    const url = `/recos/${lead._id.toString()}`;
    window.open(url);
  }

  openContract = (reco) => {
    const url = `/client/conversation/${reco.conversation._id.toString()}`;
    window.open(url);
  }

  canOpenContract = (reco) => {
    if (reco.conversation.request.state !== 'Proposal') {
      return this.openContract(reco);
    }
  }

  getCollaboratorInitials = (user) => {
    return `${user.profile.name.first[0].toUpperCase()}${user.profile.name.last[0].toUpperCase()}`;
  }

  /**
   * Custom sort function to order leads by event date.
   * - Undefined event dates will appear at the end when sorted by newest to oldest.
   * - Sort order can be adjusted by using `this.sortOrder`.
   *
   * @param {Object} lead
   * @returns {number} - Sort value based on eventDate.
   */
  customEventDateSort = (lead) => {
    if (lead.eventDate === undefined) {
      return this.sortOrder === 'eventDate' ? 0 : Infinity;
    }
    const eventDateTimestamp = new Date(lead.eventDate).getTime();
    return this.sortOrder === 'eventDate' ? eventDateTimestamp : -eventDateTimestamp;
  }

}
export const ClientDashboardListComponent = {
  controller: ClientDashboardListController,
  template: require('./client-dashboard-list.component.jade'),
  bindings: {
    leads: '=',
    showArchive: '<',
    archivedLeads: '=',
    loading: '<',
    splitLeads: '<',
    memberConfirmedLeads: '<',
    memberConcludedLeads: '<',
    sortOrder: '<'
  }
};
