import { RawVenue } from 'spc/lib/database/types/venue/venue';

const VENUE_ACCOLADES_ICON_ORDER = {
  'Michelin Stars': 1,
  'Bib Gourmand Award': 2,
  'Michelin Green Star': 3,
};

export class VenueDistinctionsCarousel {
  venue: RawVenue;
  view: 'Carousel' | 'Icon';
  accoladeUrl: String;
  ENUMS;
  VIEW_MODES = {
    CAROUSEL: 'Carousel',
    ICON: 'Icon'
  } as const;

  constructor(private $cloudinary, ENUMS) {
    'ngInject';
    this.ENUMS = ENUMS;
  }

  $onInit = () => {
    // Set 'Carousel' as default view
    this.view = this.view ? this.view : this.VIEW_MODES.CAROUSEL;
  }

  getDistinctions = (distinctions) => {
    const sortByObject = this.ENUMS.liteProfileAccolades.DISTINCTIONS.reduce((obj, item, index) => { return { ...obj, [item]: index }; }, {});
    const customSort = distinctions.sort((a, b) => sortByObject[a.accolade] - sortByObject[b.accolade]);
    const data = customSort.sort((a, b) => {
      return b.year - a.year;
    });
    return data;
  }

  scrollCards(scrollDirection) {
    const carousel = document.getElementById('distinction');
    if (scrollDirection === 'left') {
      carousel.scrollLeft -= 200;
    }
    else if (scrollDirection === 'right') {
      carousel.scrollLeft += 200;
    }
  }

  getVenueLatestAccolades = (venueAccolades) => {
    if (!(venueAccolades && venueAccolades.length)) {
      return [];
    }

    const filteredAccolades = venueAccolades.filter(accoladeData => accoladeData.accolade in VENUE_ACCOLADES_ICON_ORDER);

    const sortedAccolades = filteredAccolades.sort((a, b) => b.year - a.year);

    const latestAccolades = sortedAccolades.reduce((acc, accolade) => {
      if (!(accolade.accolade in acc)) {
        accolade.order = VENUE_ACCOLADES_ICON_ORDER[accolade.accolade];
        acc[accolade.accolade] = accolade;
      }
      return acc;
    }, {});

    return Object.values(latestAccolades);
  }
}

export const VenueDistinctionsCarouselComponent = {
  bindToController: true,
  bindings: {
    venue: '<',
    view: '<'
  },
  controller: VenueDistinctionsCarousel,
  template: require('./venue-distinctions-carousel.jade')
};
