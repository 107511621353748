import debounce from 'lodash/debounce';
import get from 'lodash/get';

import { DUser } from 'spc/lib/database/types/user';
import { RawCompany } from 'spc/lib/database/types/company';
import { ApiService } from 'spc/shared/api/api.service';
import { UserService } from 'spc/services/user.service';

class EditCompanyController {
  email: string;
  companies: RawCompany[];
  user: DUser;
  ui: {
    creatingCompany?: boolean;
    editingCompany?: boolean;
    companyName?: string;
    displaySuccessMessage?: boolean;
  };

  constructor(
    private $api: ApiService,
    private $timeout,
    private unwrapError,
    private $user: UserService
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.ui = { editingCompany: false };
  }

  private select = (company) => {
    const isAdmin = this.$user.isAdmin();
    let request;
    const companyId = get(company, '_id', null);

    if (isAdmin) {
      request = this.$api.Admin.Users.update({
        _id: this.user._id,
        company: companyId,
        roles: this.user.roles
      });
    } else {
      request = this.$api.Auth.change({
        company: companyId
      });
    }
    return request
      .then(() => {
        this.user.company = company;
        this.user.companyName = get(company, 'name', null);
        this.ui.editingCompany = false;
        this.ui.displaySuccessMessage = true;
        this.ui.companyName = '';
        this.companies = [];
        this.$timeout(() => this.ui.displaySuccessMessage = false, 2000);
      })
      .catch(error => this.unwrapError(error));
  }

  private searchCompanies = () => {
    if (!this.$user.isAdmin()) {
      return;
    }

    if (!this.ui.companyName || this.ui.companyName.length < 3) {
      return;
    }

    return this.$api.Companies.searchCompanies(this.ui.companyName)
      .then(data => this.companies = data.companies);
  }

  public debouncedSearchCompanies = debounce(this.searchCompanies, 300);

  private findExactMatch = () => {
    if (!this.companies || !this.ui.companyName) {
      return;
    }
    return this.companies.find(company => company.name.toLowerCase().trim() === this.ui.companyName.toLowerCase().trim());
  }

  public allowCreateCompany = () => {
    return this.ui.companyName && !this.findExactMatch();
  }

  getDomain = () => {
    if (!get(this.user, 'profile.email')) {
      return;
    }
    const email = get<string>(this.user, 'profile.email');
    return email.split('@')[1];
  }

  public createCompany = () => {
    return this.$api.Companies.createCompany({ name: this.ui.companyName, domains: [this.getDomain()] })
      .then(data => this.select(data.company))
      .catch(error => this.unwrapError(error));
  }

  public handleBlur = () => {
    if (!this.ui.companyName) {
      return;
    }
    const company = this.findExactMatch();
    if (company) {
      this.select(company);
    } else {
      this.createCompany();
    }
  }

  public startEditing = () => {
    this.ui.editingCompany = true;
  }

  public removeCompany = () => {
    return this.select(null);
  }

  public cancelEditing = () => {
    this.ui.companyName = '';
    this.companies = [];
    this.ui.editingCompany = false;
  }
}

export const editCompanyComponent = {
  controller: EditCompanyController,
  template: require('./edit-company.component.jade'),
  bindings: {
    user: '<'
  }
};

