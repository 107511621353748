import { debounce, get } from 'lodash';
import moment from 'moment';
import { DLead } from 'spc/lib/database/types/lead';
import { DVenue } from 'spc/lib/database/types/venue/venue';
class ArchiveEventController {

  archiveReasons: string[];
  archiveData: {
    archiveReason?: string;
    alternativeVenue?: string;
  };
  close: (isArchived: boolean) => void;
  venueSuggestions: DVenue[];
  showVenueSuggestion: boolean;

  lead: DLead;
  constructor(private $api, private ENUMS, private unwrapError) {
    'ngInject';
  }
  $onInit() {
    this.archiveReasons = this.ENUMS.concierge.archiveReason;
  }
  needVenueName = () => {
    return ['I Found Another Venue (Off SixPlus)', 'I Booked A Venue Offline'].includes(get(this.archiveData, 'archiveReason'));
  }

  saveArchiveData = () => {
    this.$api.Leads.archive({ lead: this.lead, archiveData: this.archiveData })
      .then((res) => {
        res.updatedLead.eventDate = moment(res.updatedLead.request.date).utc();
        this.close({ isArchived: true, updatedLead: res.updatedLead });
      });
  }

  getVenueNameSuggestions = () => {
    if (this.archiveData.alternativeVenue.length < 3) {
      this.showVenueSuggestion = false;
      return;
    }

    return this.$api.Venues.get(null, { name: this.archiveData.alternativeVenue, menus: false, drinks: false, limitToVisible: true, skipAccount: true })
      .then(({ data }) => {
        this.showVenueSuggestion = true;
        this.venueSuggestions = get(data, 'data.venues', []);
      })
      .catch(error => this.unwrapError(error));
  }

  debouncedVenueNameSuggestions = debounce(this.getVenueNameSuggestions, 500);

  updateAlternativeVenue = (venue?) => {
    const venueName = venue ? get(venue, 'data.name') : get(this.archiveData, 'alternativeVenue');
    this.archiveData.alternativeVenue = venueName;
    this.showVenueSuggestion = false;
    return;
  }
}

export const ArchiveEventComponent = {
  template: require('./archive-event.component.jade'),
  controller: ArchiveEventController,
  bindings: {
    close: '&',
    lead: '<'
  }
};
