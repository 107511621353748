import get = require('lodash/get');

export class ArchiveService {
  constructor(private ENUMS, private $clickOk, private unwrapError, private archiveEventModal, private toast, private $user) {
    'ngInject';
  }

  archiveEvent = (lead) => {
    // Display a toast notification and return if the logged-in user is not the primary client of the lead
    if (get(lead, 'primaryClient._id') !== get(this.$user, '$._id')) {
      this.toast.badNews('Failed', 'Collaborators and team members are not authorized to archive events.');
      return;
    }

    const activeProposalStates = this.ENUMS.bookingRequestState.activeProposalStates;
    const activeProposal: number = lead.recommendations.filter(reco => activeProposalStates.includes(get(reco, 'conversation.request.state'))).length;
    const isProposalWithDeposit = lead.recommendations.some(reco => get(reco, 'conversation.request.state') === 'DEPOSIT');

    if (isProposalWithDeposit) {
      const message = `You have already paid a deposit to a venue. Please contact the venue to cancel the event before archiving.`;
      const showCancel = false;
      return this.$clickOk(message, showCancel);
    } else {
      const message = activeProposal ? `Are you sure you want to archive this event? You have ${activeProposal} active ` + (activeProposal > 1 ? 'requests/proposals ' : 'request/proposal ') + `associated with this event. Archiving this event will cancel any active requests or proposals and notify venues of your cancelation.` : `Are you sure you want to archive this event?`;
      const showCancel = true;
      return this.$clickOk(message, showCancel)
        .then((response) => {
          if (get(response, 'value.cancel')) {
            return;
          }

          // Open archive event modal
          return this.archiveEventModal(lead)
            .then(res => get(res, 'value'));
        })
        .catch(error => this.unwrapError(error));
    }
  }
}
