import { ApiService } from 'spc/shared/api/api.service';
import { UserService } from 'spc/services/user.service';
import { RecoRequestService } from 'spc/recos/reco-request/reco-request.service';
import get from 'lodash/get';

import { RawVenue } from 'spc/lib/database/types/venue/venue';
import { RawDrink } from 'spc/lib/database/types/drink';
import { RawMenu } from 'spc/lib/database/types/menu';
import { ReviewsService } from '../../reviews/reviews.service';
import { RawUser } from 'spc/lib/database/types/user';
import { getYelpRatingColor } from 'spc/shared/yelp-star-color';

class ListVenueViewController {
  venueId: string;
  listId: string;
  venue: RawVenue;
  drinks: RawDrink[];
  menus: RawMenu[];
  loading: boolean;
  user: RawUser;
  isPremiumUser: boolean = false;
  ui: 'VENUE' | 'HISTORY' | 'NOTES';
  UI_TABS = {
    VENUE: 'VENUE',
    HISTORY: 'HISTORY',
    NOTES: 'NOTES'
  } as const;

  constructor(
    private $api: ApiService,
    private $user: UserService,
    private unwrapError,
    private spaceDetailModal,
    private menuDetailModal,
    private drinksDetailModal,
    private photoDialog,
    private $cloudinary,
    private reviewsService: ReviewsService,
    private recoRequestService: RecoRequestService,
    private recoRequestModal,
    private eventWithLiteModal,
    private requestConfirmationModal,
  ) {
    'ngInject';
    this.ui = this.UI_TABS.VENUE;
  }

  $onInit () {
    this.loading = true;
    return this.fetchVenue();
  }

  fetchVenue () {
    return this.$api.Venues.get(this.venueId, {
      menus: true,
      drinks: true,
      getNonVisible: false,
      profileOwner: false,
      listView: true,
      _id: this.venueId
    })
    .then((res) => {
      const { venue, drinks, menus } = res.data.data;
      this.venue = venue;
      this.venue.data.spaces = this.filterSpaces(this.venue.data.spaces);
      this.venue.data.addOns = this.filterAddOns(this.venue.data.addOns);
      this.drinks = drinks;
      this.menus = menus;
      this.setUser();
      this.loading = false;
      this.venue.data.ratingAndReviews.forEach((review) => {
        review['stars'] = this.reviewsService.calculateStars(review.rating);
        if (review.name === 'Yelp') {
          review.starColor = getYelpRatingColor(review.rating);
        }
      });
    })
    .catch(error => this.unwrapError(error));
  }

  openRecoRequestModal = async(params) => {
    this.recoRequestModal(params)
      .then((data) => {
        const { request, status, lead } = get(data, 'value', {});
        return this.requestConfirmationModal(request, status, lead);
      });
  }

  addToEvent = (params) => {
    if (!this.isPremiumUser) {
      return;
    }
    this.eventWithLiteModal({ status: params.venue.status, venue: params.venue, user: this.user })
      .then( (res) => {
        if (res.value.proceed) {
          this.openRecoRequestModal(params);
        }
      });
    }

  setUser() {
    this.user = this.$user.$ || null;
    if (this.user && this.user.accountTier) {
      this.isPremiumUser = this.user.accountTier.includes('Pro') || this.user.accountTier.includes('Premium') ? true : false;
    }
  }

  showNotes () {
    const user = this.user;
    if (!user) {
      return false;
    }

    const venueStatus = this.venue.status;

    if (venueStatus !== 'Published' && venueStatus !== 'Lite' && venueStatus !== 'Private' && !this.isPremiumUser) {
      return false;
    }

    return true;
  }

  filterSpaces (spaces) {
    return spaces.filter(space => space.isVisible);
  }

  filterAddOns (addOns) {
    return addOns.filter(a => !a.isDeleted);
  }

  openPhotoDialog = ({ photos, initialPhoto }): Promise<any> => {
    return this.photoDialog({ photos: photos, initialPhoto: initialPhoto });
  }

  toggleHistory () {
    this.ui = this.ui === this.UI_TABS.VENUE ? this.UI_TABS.HISTORY : this.UI_TABS.VENUE;
  }
}

export const ListVenueViewComponent = {
  controller: ListVenueViewController,
  template: require('./list-venue-view.component.jade'),
  bindings: {
    venueId: '<',
    ui: '<',
    listId: '<'
  }
};
